:root{

    --canvas-border: teal;
    --canvas-bg: none;

	--center-stroke: white;
	--center-fill: white;
	--center-active-stroke: var(--pale-yellow);
	--center-active-fill: var(--pale-yellow);
	--center-disabled-stroke: var(--pale-grey);
	--center-disabled-fill: var(--pale-grey);

	--center-hover-stroke: white;
	--center-hover-fill: white;
	--ring-stroke: var(--pale-yellow);
	--ring-fill: rgba(238, 231, 131, 0.1);

	--test: rgb(134, 58, 8);;
	--house-stroke: var(--test);
	// --house-stroke: var(--light-grey);
	--house-fill: var(--test);
	// --house-fill: var(--light-grey);
	--house-covered-stroke: var(--green);
	--house-covered-fill: var(--green);

}