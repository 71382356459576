.gameCard{
    position: relative;
    background-color: rgb(155, 113, 59);; 
    color: blue;
    // max-width: 40%; 
    height: 200px;;
    flex-basis: 40%; 
    flex-grow: 1;
    border-radius: 1rem;
    color: white;
    font-family: sans-serif;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: flex-start; 
    flex-direction: column;
    ;
 
    // h1, .spacer{
    //     flex-basis: 1px;
    //     flex-grow: 1;
    //     height: 2rem;;
    //     display: block;
    //     width: 100%;
    //     margin: 1rem;

    &:hover, &:focus{

        background-color:  lighten(rgb(155, 113, 59),10%)

    }

    &.tiles{
        background-color: var(--tiles-game-bg);
        &:hover, &:focus{
            background-color: var(--tiles-game-bg-highlight);

        }
        >div{
            position: absolute;
            top: 40%;
            // top: calc(50% - (var(--tile-max-size) / 2 ));
            left: calc(50% - (var(--tile-max-size) / 2 ));

            transform: rotate(-10deg);; 
        }
    }
    &.flowers{
        background-color: var(--flowers-colour);
        &:hover, &:focus{
            background-color: var(--flowers-colour-highlight);

        }
    }
    &.words{
        background-color: var(--words-colour);
        &:hover, &:focus{
            background-color: var(--words-colour-highlight);
        }
    }
    color: var(--text-dark);
    &.bubbles{
        background-color: var(--bubbles-colour);
        &:hover, &:focus{
            background-color: var(--bubbles-colour-highlight);
        }
    }
}

@media screen and  (max-width: 600px){
    
    .gameCard{
        // justify-content: space-between;
        :first-child{

        }

    &:nth-child(2n -1){
        border-radius: 0  1rem 1rem 0;
    }
    &:nth-child(2n ){
        border-radius: 1rem 0 0  1rem ;
    }

    }

}