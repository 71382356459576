:root{

    --inventory-default-fill: green;
    --inventory-default-stroke: black; 
    --inventory-used-fill: #444;
    --inventory-used-stroke: black;
    --inventory-next-fill: aquamarine; 
    --inventory-next-stroke: orange;

    --polygon-stroke: black;
    --polygon-fill: orange;

    --candidate-stroke:#555;
    --candidate-fill: rgba(150,150,150,.3);
    --candidate-hover-fill: aquamarine;



} 