.help {
	/* margin: 8rem 8rem; */
	background-color: var(--word-shield);
	position: absolute;
	border: solid 4px var(--text);
	color: var(--text);
	height: auto;
	inset: 0;
	top: var(--top-bar-height);
	h1 {
		margin: 1rem 1rem 2rem 1rem;
		font-weight: bold;
	}

	button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		border: none;
		background: unset;
		font-size: 1.5rem;
		color: var(--text);
	}

	li {
		margin: 1rem;
	}
	ul {
		flex-direction: column;
	}
}

.helpOverlay {
	background-color: color-mix(in srgb, var(--word-shield) 20%, transparent);
}
@media screen and (max-width: 600px) {
	.help {
		// left: 1rem;
		// right: 1rem;
		// height: unset;

		left: 0;
		right: 0;
		bottom: 0;
	}
}
