.gameIntro{

    width: 100%;
    height: 100%;;

    button{
        margin: 0 auto; 
        display: block;
        width: 40%;;
        flex-grow: 0;
        // width: 2rem;;
    }

    h1,h2{
        text-align: center;
    }
    h1 {
        font-size: 3rem;
    }

    .explainerImage{
        width: 70%;
    }

    >div{

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
    }

}


.tiles{
    background-color: var(--tiles-game-bg);
}
.words{
    background-color: var(--words-colour);
    color: var(--text-dark);
    text-shadow: 0px 0px 1px #000;
    h2{
        font-weight: 400;;
    }
}

.hide{
    display: none;
}