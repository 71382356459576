.homePage{
    
}



.gamesContainer{
    display: flex;
    width: 100%;
    gap: 2rem;
    flex-wrap: wrap;

    align-content: flex-start;
    justify-content: center;
    // align-items: flex-start;
}

