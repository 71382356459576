.keyboard {
	padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	text-transform: uppercase;
	font-weight: bold;
	height: 11.75rem;
	background: unset;
	transition: background-color;
	transition-duration: 10000ms;
	transition-delay: inherit !important;
}
.keyboard * {
	transition: inherit !important;
}

.keyboard .hg-rows {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.keyboard .hg-row {
	flex-grow: 1;
}

.keyboard .hg-button {
	height: 100%;
	background-color: var(--keyboard-key);

	box-shadow: none;
	border: none;
	outline: none;
}

.keyboard .incorrect {
	background-color: var(--incorrect);
}
.keyboard .misplaced {
	background-color: var(--misplaced);
}

.keyboard .correct {
	background-color: var(--correct);
}
.keyboard .currentGuess {
	/* background-color: var(--default); */
}
