.page{
 
height: 100%;
display: flex;
flex-direction: column;

}

.tiles{
    // background: var(--tiles-game-bg);
    color: var(--tiles-game-text);
    svg{
        stroke: var(--tiles-game-text);
    }
    --border-bottom-colour: var(--tiles-game-text);
}

