:root{


	--petal: var(--red);
	--connected-petal: var(--yellow);
	--stem: var(--green);
	--rock: var(--brown);
	--start: var(--pale-yellow);
	--end: var(--pale-yellow);	
    --board: #777;



}