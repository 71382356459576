$green: #0e6747;
:root{ 

	// colours
	--green: #09412c;
	--light-green: #09412c;
	--pale-blue: #9ed0f7;
	--pale-purple: #a97ee6;
	--yellow: #e7d465;
	--pale-yellow: #eee783;
	--red: #dd5419;
	--dark-red: #c32222;
	--blue: #86a1cc;
	--light-red: #e76e3a;
	--brown: #1b0a03;
    --light-grey: #ccc;
	--pale-grey: #606060;
	--dark-grey: #2c2c2c;

	//site 

	--toast-error-bg: var(--dark-red);
	--toast-info-bg: var(--blue);

	--bg-main: #282828;
	--bg-secondary: #32302f; 
	--text: #ddc7a1;
	--text-light: var(--text);
	--text-dark: #111;
    

	--border-bottom-colour: var(--text);


	// --tiles-game-bg: var(--bg-main);
	--tiles-game-bg: color-mix(in srgb,var(--green),#fff 5%);
	--tiles-game-bg-highlight: color-mix(in srgb,var(--tiles-game-bg),#fff 10%);;
	--tiles-game-text: var(--text);

	--flowers-colour: var(--light-red);
	--flowers-colour-highlight: color-mix(in srgb,var(--flowers-colour),#fff 10%);
	--bubbles-colour: var(--pale-blue);
	--bubbles-colour-highlight: color-mix(in srgb,var(--bubbles-colour),#fff 10%);
	--words-colour: var(--pale-purple);
	--words-colour-highlight:color-mix(in srgb,var(--words-colour),#fff 10%);

 


    // from word game
	// --green: #6e9c78;
	// --red: #c32222;
	// --orange: #da6f1d;
	// --orange2: #cb6b21;
	// --orange3: #b9733d;
	// --border: #444;
	// --grey: rgb(150, 150, 150);
	// --blue: #86a1cc;
	// --top-bar-height: 4rem;
	// --darkish-grey: #3d3d3d;
	// --black: #121212;
	// --black2: #181818;
	// --black3: #1f1f1f;
	// --sky-blue: #8df2f9;
	// --sky-blue2: #9cdcfe;
	// --test: #529cb3;
	// --white: #eee;


}