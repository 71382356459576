.centerColumn{


        margin: 0 auto;
        width: 650px;
        overflow: hidden;
        flex-grow: 1;
    

}

@media screen and (max-width: 650px){
    .centerColumn{
        margin: 0;
        max-width: 100vw;
    }
}


.buttonGroup{
    width: 80%;
    display: flex;
    // align-self: stretch;
    margin-bottom: 1rem;
    flex-direction: row;
    gap: 1rem;
    margin: 0 2rem ;
    // justify-self: flex-end;
    button{
        flex-grow: 1;
        align-self: stretch;
    }
}


#polygons-game.hide-candidates{
    polygon[data-type="candidate"]{
        display: none !important;
    }
}