:root{


	--incorrect: #000;
	// --incorrect: var(--dark-grey);
	--correct: #6e9c78;
	--misplaced: #b9733d;
	// --default: #529cb3;;
	--default: var(--words-colour);
	/* --default: var(--sky-blue); */
	// --word-shield: var(--black3);

	--word-shield: var(--bg-main);

	--keyboard-key: var(--pale-grey);

	--flip-duration: 350ms;

    --top-bar-height: 4rem;

}


@media (max-width: 600px) {
	:root {
		--top-bar-height: 3rem;
	}
}