:root{
    --board: #777;
	--grid: var(--bg-main);
	--tile-bg: rgb(215, 177, 106);
	--tile-text: var(--bg-main); 

	--tile-max-size: 70px;
	--tile-font-size: 2.75rem;

	--tile-gap: 6px;

	--square-border: #917e5c;
	--square-bg: #09412c; 
	--tile-success: #0e6747;

	--rack-width: calc((6 *  var(--tile-max-size) ) + 5 *  var(--tile-gap));
	--rack-height: calc((2 *  var(--tile-max-size) ) + 1 *  var(--tile-gap));

}


@media screen and (max-width:400px ){ 
	:root{
		--tile-font-size: 2.25rem;
		--tile-max-size: 60px;
	}
}

@media screen and (max-width:650px){ 
	:root{

		
		--rack-width: calc((4 *  var(--tile-max-size) ) + 3 *  var(--tile-gap));
		--rack-height: calc((3 *  var(--tile-max-size) ) + 2 *  var(--tile-gap));
		
	}
}