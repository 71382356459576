button {
	background: inherit;
	padding: 0;
	box-shadow: none;
	border: none;
}

table{
    border-collapse: collapse;
}
td{
    display: table-cell;
    vertical-align: inherit;;
    unicode-bidi: isolate;
}


h1,h2,h3,h4,h5,h6{
    font-weight: bold;;
}

h1{
    font-size: 2rem;
}

h2{
    font-size: 1.5rem;
}