.tileButton{
    cursor: grab;
    // position: absolute;;
    // z-index: 9999;;
    // pointer-events: all;
}
 
.isPreview{
    // border: solid 3px red;
    position: absolute;
    top: -100px; 
}

.isDragging{
    // outline: solid 2px white;
    opacity: 0;
    // margin: 0; 
    // width: 800px ;
    // height: 800px;
    overflow: hidden;
    
    // TODO - having this enabled makes the drag reordering stuff work perfectly 
    //as it takes the dragged item out of the flow so stuff can move around it
    // however it breaks HTML5 (mouse) dragging of anything except the first ile
    // position: absolute;; 


    // left: -400px;
    // margin-left: -6px;

    &.budgeRight, &.budgeLeft{
        margin: 0 !important;
        // width: 0;
    }
    &.budgeRight{
        // position: relative;
        // width: var(--tile-max-size);
    }
} 

.budgeRight{
    margin-left: calc( var(--tile-max-size) + var(--tile-gap));
}
.budgeLeft{
    margin-right: calc( var(--tile-max-size) + var(--tile-gap));
}

.tile{
    cursor: grab;
    font-family: "Noto Sans Mono";
    flex-grow: 0;
    width: var(--tile-max-size);
    height: var(--tile-max-size);
    flex-basis: var(--tile-max-size);
    max-width: var(--tile-max-size);
    max-height: var(--tile-max-size);
    background-color: var(--tile-bg);;
    font-size: var(--tile-font-size);
     
    border-radius: 2px;;
    text-align: center;
    vertical-align: middle;
    line-height: var(--tile-max-size);;
    font-weight: 400;;

    &.isOnBoard{ 
        border-radius: 2px;;
    }

    &.active{
        // outline: solid 3px green;
        box-shadow: 0 0 12px aquamarine inset;
    }

 
    
    &.hide{ 
        // opacity: 0.0001 !important;
        opacity: 0;
    }

   

} 