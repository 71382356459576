@import "animations.scss";
@import "variables.scss";
@import "reset.scss";
@import "defaults.scss";
@import "keyboard.scss";
@import "global.scss";


html, body, #root {
	height: 100vh;
	width: 100vw;
	display: block;
	position: relative;
}

body {
	height: 100%;
	width: 100%;
	display: block;
	position: relative;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color: var(--text);
	background: var(--bg-main);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


h1,
h2 {
	margin: 1rem;
}

#flowers-game {
	/* width: 320px; */
	height: 80vh;
	text-align: center;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}


@keyframes move {
	0% {
		offset-distance: 0%;
	}
	100% {
		offset-distance: 100%;
	}
}
@keyframes poof {
	0% {
		opacity: 1;
		transform: (1);
	}
	100% {
		transform: scale(0.01);
		opacity: 0;
	}
}
@keyframes glow {
	0% {
		background-color: var(--petal);
	}
	100% {
		background-color: var(--start);
	}
}
 